<template>
  <b-card>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-row>
        <!-- title -->
        <b-col md="12">
          <validation-provider
            v-slot="validationContext"
            ref="title"
            :rules="{ required: true }"
            vid="title"
            name="Title"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="title"
                placeholder="Title"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <!-- Description -->
      <b-row class="mt-2">
        <b-col md="12">
          <div>
            <label for="textarea-default">Description</label>
            <aom-text-editor
              v-if="showAOMTextEditor"
              v-model="description"
              :tool-bar="toolBar"
              :height="100"
              placeholder=""
            />
          </div>
        </b-col>
      </b-row>

      <!-- Is public -->
      <b-row class="mt-2">
        <b-col md="12">
          <b-form-group
            label="Visible to participants"
            label-for="is_public"
          >
            <b-form-checkbox
              id="is_public"
              v-model="selectedIsPublic"
              checked="true"
              name="active-is-public"
              switch
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Has Detail Page -->
      <b-row class="mt-2">
        <b-col md="12">
          <b-form-group
            label="Has Detail Page"
            label-for="has_detail_page"
          >
            <b-form-checkbox
              id="has_detail_page"
              v-model="selectedHasDetailPage"
              checked="true"
              name="active-has-detail-page"
              switch
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Date -->
      <b-row class="mt-2">
        <b-col md="12">
          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-form-datepicker
              v-model="dateTime"
              :locale="browserLocale"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- submit and reset -->

      <b-row class="mt-5">
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="clearForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            <b-spinner
              v-if="isUpdating"
              small
            />
            <span v-if="isUpdating"> Updating...</span>
            <span v-else>Submit</span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BFormDatepicker,
  BSpinner,
  BFormCheckbox
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import AomTextEditor from "@aom-core/AoMTextEditor.vue";
import keyDatesService from "@/services/keyDatesService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { getValidationState } from "@/libs/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required } from "@validations";
import { AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS } from '@/constants/aom-text-editor';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
    AomTextEditor,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BSpinner,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  directives: {
    Ripple,
  },
  props: {
    showAOMTextEditor: {
      type: Boolean
    },
  },
  data() {
    return {
      dateTime: new Date().toISOString().replace(/T.*/,'').split('-').join('-'),
      matchOptions: null,
      title: null,
      description: null,
      selectedIsPublic: false,
      selectedHasDetailPage: false,
      isUpdating: false,
      browserLocale: navigator.language,
      toolBar: AOM_TEXT_EDITOR_TOOLBAR_WITHOUT_MERGE_TAGS
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isUpdating = true;
        await keyDatesService.add(this.$route.params.id, {
          title: this.title,
          description: this.description,
          date: this.dateTime,
          is_public: this.selectedIsPublic,
          has_detail_page: this.selectedHasDetailPage,
          actionable_event: null,
        });

        this.$toast(makeSuccessToast("Key Date added successfully."));
        this.clearForm();
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        }
        this.$log.error(e);
        this.$toast(makeErrorToast(data.message));
      } finally {
        this.isUpdating = false;
      }
    },

    clearForm() {
      this.title = "";
      this.dateTime = new Date().toISOString().replace(/T.*/,'').split('-').join('-');
      this.description = null;
      this.selectedIsPublic = false;
      this.selectedHasDetailPage = false;
      this.$root.$emit("bv::toggle::collapse", "add-key-date-right");
      this.$emit("added");
    },
  },
  setup() {
    return {
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
#add-key-date-right___title__ {
  font-size: 18px !important;
  font-weight: 500 !important;
  width: 95% !important;
}

.b-sidebar > .b-sidebar-header {
  display: flex;
  flex-direction: row-reverse;
}
.category > div div div div span {
  background-color: orange;
}
</style>
