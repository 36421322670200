<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <div
        v-if="props.column.field === 'title' && !isSystemKeyDate(props.row)"
        cols="12"
        md="2"
        style="display: flex"
      >
        <b-link @click="editKeyDate(props.row.id)">
          {{ props.row.title }}
        </b-link>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-else-if="props.column.field === 'description'"
        v-html="props.formattedRow[props.column.field]"
      />
      <!-- eslint-disable -->

      <!-- Column: Actions -->
      <div
        v-else-if="props.column.field === 'action'"
        cols="12"
        md="2"
        class="d-flex vgt-center-align"
      >
        <div v-if="!isSystemKeyDate(props.row)">
          <action-button
            variant="outline-none"
            @click="deleteKeydate(props.row.id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="20"
            />
          </action-button>
        </div>
        
        <div v-else>
          <b-button variant="outline-none">
            <feather-icon 
              v-b-tooltip.hover.top="'This is a system generated key date. Please go to applications section to edit this'"
              icon="InfoIcon" 
              size="20"
            />
          </b-button>
        </div>
      </div>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { BButton, VBToggle, VBTooltip, BLink } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ActionButton from "@/views/components/common/ActionButton.vue";
import {
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import keyDatesService from "@/services/keyDatesService";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import { isPublicOptions, hasDetailPageOptions } from "@/models/eKeyDates";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BButton,
    TablePagination,
    BLink,
    ActionButton
  },
  directives: {
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      element: {},
      isLoading: false,
      dir: false,
      columns: [
        {
          label: "Title",
          field: "title",
          filterOptions: {
            enabled: true,
            placeholder: "Search Title",
          },
        },
        {
          label: "Description",
          field: "description",
          filterOptions: {
            enabled: true,
            placeholder: "Search description",
          },
        },
        {
          label: "Visible to participants",
          field: "is_public",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: isPublicOptions,
            filterFn: this.programTypeFilter
          },
          width: "15em",
        },
        {
          label: "Has Detail Page",
          field: "has_detail_page",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: hasDetailPageOptions,
          },
          width: "8em",
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
          width: "10em",
        },
        {
          field: "action",
          sortable: false,
          width: "6em",
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
      isPublicOptions,
      hasDetailPageOptions
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  mounted() {
    const inputs = document.getElementsByName("vgt-date");
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
      });
    });
  },
  methods: {
    isSystemKeyDate(keyDate) {
      return keyDate.id === 0;
    },
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of ["title", "description", "is_public", "has_detail_page", "date"]) {
        if (params.columnFilters[col]) {
          switch (col) {
          case "is_public":
          case "has_detail_page":
            columnFilters.push({
              field: col,
              value: params.columnFilters[col] === "true" ? 1 : 0,
            });
            break;
          default:
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
            break;
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await keyDatesService.getList(this.$route.params.id, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          ...item,
          date: localeDateStringFromIsoDateTimeWithoutTimezone(item.date, false),
          is_public: item.is_public || this.isSystemKeyDate(item) ? "Yes": "No",
          has_detail_page: item.has_detail_page ? "Yes": "No",
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Key dates list not loaded."));
        this.$log.error(e);
      }
      this.isLoading = false;
    },

    editKeyDate(id) {
      this.$nextTick(() => {
        this.$emit("edit", id);
      });
    },

    deleteKeydate(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this Key Date ?", {
          title: "Delete Key Date",
          size: "sm",
          okVariant: "primary",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              this.isLoading = true;
              await keyDatesService.delete(this.$route.params.id, id);
              this.loadItems();
              this.$toast(makeSuccessToast("Key Date deleted successfully."));
            } catch (e) {
              this.$toast(makeErrorToast("Something went wrong. Try again"));
              this.$log.error(e);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, 
      perPage, 
      page
    };
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 400;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
