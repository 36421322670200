<template>
  <editor
    v-model="richText"
    :api-key="apiKey"
    :init="{
      height: height,
      min_height: minHeight,
      menubar: menuBar,
      plugins: plugins,
      toolbar: toolBar,
      file_picker_types: 'image',
      images_files_types: 'jpeg,jpg,png',
      file_picker_callback: filePickerCallback,
      relative_urls: false,
      remove_script_host: false,
      document_base_url: documentBaseUrl,
      
      setup: (editor) => {
        editor.ui.registry.addMenuButton('aommergetags', {
          text: 'Merge Tags',
          fetch: (callback) => {
            var items = Object.values(mergeTags).map((mergeTag) => ({
              'type': `menuitem`,
              text: mergeTag.text,
              onAction: function () {
                editor.insertContent(mergeTag.tag);
              },
            }));
            callback(items);
          },
        });
      },
    }"
    :placeholder="placeholder"
    @input="updateEvents"
  />
</template>

<script>
/* eslint-disable no-unused-vars */
const tinyMceToolbar =
  "aommergetags | undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | link | help ";
const tinyMcePlugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "charmap",
  "print",
  "preview",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "autoresize",
  "insertdatetime",
  "media",
  "table",
  "paste",
  "code",
  "help",
  "wordcount",
];

import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from "@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE";
import { mergeTags as MERGETAGS } from "@/models/enums/mergeTags";
import { emailComposeTypes } from "@/models";
import { AOM_MAIN_DOMAIN } from "@/constants/app";

export default {
  components: {
    editor: Editor,
  },
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 500,
    },
    minHeight: {
      type: Number,
      default: 200,
    },
    menuBar: {
      type: Boolean,
      default: false,
    },
    toolBar: {
      type: String,
      default: tinyMceToolbar,
    },
    plugins: {
      validator(value) {
        return Array.isArray(value)
          ? value.every(v => tinyMcePlugins.indexOf(v) !== -1)
          : tinyMcePlugins.indexOf(value) !== -1;
      },
      default: () => tinyMcePlugins,
    },
    type: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Compose a message...",
    },
  },
  data() {
    return {
      richText: "",
      mergeTags: MERGETAGS,
      documentBaseUrl: "",
    };
  },
  created() {
    this.documentBaseUrl = `${window.location.protocol}//${AOM_MAIN_DOMAIN}`;
    this.richText = this.value;
    if (this.type === emailComposeTypes.INVITES) {
      this.$watch("value", function () {
        this.richText = this.value;
      });
      let {
        MERGE_TAG_FIRST_NAME,
        MERGE_TAG_LAST_NAME,
        MERGE_TAG_PHONE,
        MERGE_TAG_EMAIL,
        MERGE_TAG_APPLY_BUTTON,
        ...tags
      } = MERGETAGS;
      this.mergeTags = tags;
    } else if (this.type === emailComposeTypes.INVITE_TEMPLATES) {
      let {
        MERGE_TAG_FIRST_NAME,
        MERGE_TAG_LAST_NAME,
        MERGE_TAG_PHONE,
        ...tags
      } = MERGETAGS;
      this.mergeTags = tags;
    }
  },
  methods: {
    // eslint-disable-next-line
    filePickerCallback(callback, value, meta) {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.addEventListener("change", e => {
        const ALLOW_TYPE = ["jpg", "jpeg", "png"];
        const ALLOW_BLOD_TYPE = ["image/jpeg", "image/png"];
        const MAX_SIZE_UPLOAD = 5 * 1024 * 1024; // 1Mb
        const file = e.target.files[0];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!ALLOW_TYPE.includes(fileExtension)) {
          callback("", { title: "" });
          return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          /* Note: Now we need to register the blob in TinyMCEs image blob registry. In the next release this part hopefully won't be necessary, as we are looking to handle it internally. */
          const id = "blobid" + new Date().getTime();
          const blobCache = getTinymce()?.activeEditor?.editorUpload?.blobCache;
          const base64 = reader.result.split(",")[1];
          if (blobCache && file && base64) {
            const blobInfo = blobCache?.create(id, file, base64);
            if (
              blobInfo.blob().size < MAX_SIZE_UPLOAD ||
              ALLOW_BLOD_TYPE.includes(blobInfo.blob().type)
            ) {
              blobCache?.add(
                blobInfo
              ); /* call the callback and populate the Title field with the file name */
              callback(blobInfo?.blobUri(), { title: file.name });
              return;
            }
          }
          // Default will reset file input
          callback("", { title: "" });
        });
        reader.readAsDataURL(file);
      });
      input.click();
    },
    updateEvents() {
      this.$emit("update", this.richText);
      this.$root.$emit("text-editor", this.richText);
    },
  },
  setup() {
    const apiKey = `${process.env.VUE_APP_TINY_MCE_API_KEY}`;
    return {
      apiKey,
    };
  },
};
</script>