export const mergeTags = Object.freeze({
  MERGE_TAG_FIRST_NAME: {
    tag: '{{FIRST_NAME}}',
    text: 'First name'
  },
  MERGE_TAG_LAST_NAME: {
    tag: '{{LAST_NAME}}',
    text: 'Last name'
  },
  MERGE_TAG_PHONE: {
    tag: '{{PHONE}}',
    text: 'Phone'
  },
  MERGE_TAG_EMAIL: {
    tag: '{{EMAIL}}',
    text: 'Email'
  },
  MERGE_TAG_PROGRAM_URL: {
    tag: '{{PROGRAM_URL}}',
    text: 'Program URL'
  },
  MERGE_TAG_PROGRAM_NAME: {
    tag: '{{PROGRAM_NAME}}',
    text: 'Program name'
  },
  MERGE_TAG_CLIENT_NAME: {
    tag: '{{CLIENT_NAME}}',
    text: 'Organisation name'
  },
  MERGE_TAG_APPLY_BUTTON: {
    tag: '{{APPLY_BUTTON}}',
    text: 'Apply button'
  },
  MERGE_TAG_EMAIL_SIGNATURE: {
    tag: '{{EMAIL_SIGNATURE}}',
    text: 'Email signature'
  },
});

