export const keyDateActive = Object.freeze({
    KEY_DATE_ACTIVE_1: 1,
    KEY_DATE_ACTIVE_2: 2,
    KEY_DATE_ACTIVE_3: 3,
    KEY_DATE_ACTIVE_4: 4,
    KEY_DATE_ACTIVE_5: 5
  });
export const keyDateActiveDisplay = [
    { value: [keyDateActive.KEY_DATE_ACTIVE_1], text: 'A Key Date 1'},
    { value: [keyDateActive.KEY_DATE_ACTIVE_2], text: 'A Key Date 2'},
    { value: [keyDateActive.KEY_DATE_ACTIVE_3], text: 'A Key Date 3'},
    { value: [keyDateActive.KEY_DATE_ACTIVE_4], text: 'A Key Date 4'},
    { value: [keyDateActive.KEY_DATE_ACTIVE_5], text: 'A Key Date 5'}
];

export const keyDateComplete = Object.freeze({
    KEY_DATE_COMPLETE_1: 1,
    KEY_DATE_COMPLETE_2: 2,
    KEY_DATE_COMPLETE_3: 3,
    KEY_DATE_COMPLETE_4: 4,
    KEY_DATE_COMPLETE_5: 5
  });
export const keyDateCompleteDisplay = [
    { value: [keyDateComplete.KEY_DATE_COMPLETE_1], text: 'C Key Date 1'},
    { value: [keyDateComplete.KEY_DATE_COMPLETE_2], text: 'C Key Date 2'},
    { value: [keyDateComplete.KEY_DATE_COMPLETE_3], text: 'C Key Date 3'},
    { value: [keyDateComplete.KEY_DATE_COMPLETE_4], text: 'C Key Date 4'},
    { value: [keyDateComplete.KEY_DATE_COMPLETE_5], text: 'C Key Date 5'}
];

export const isPublicOptions = [
  {value: true, text: 'Yes'},
  {value: false, text: 'No'}
];

export const hasDetailPageOptions = [
  {value: true, text: 'Yes'},
  {value: false, text: 'No'}
];



