<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <action-button
            v-b-toggle.add-key-date-right
            variant="primary"
            style="margin-left: 10px"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
              class="mr-50"
            />
            <span class="text-nowrap">Add Key Date</span>
          </action-button>

          <b-sidebar
            id="add-key-date-right"
            title="Add Key Dates"
            bg-variant="white"
            right
            shadow
            backdrop
            width="600px"
            @shown="handleShowAOMTextEditor"
            @hidden="handleHideAOMTextEditor"
          >
            <div>
              <add-key-date
                :show-a-o-m-text-editor="showAOMTextEditor"
                @added="refreshList"
              />
            </div>
          </b-sidebar>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <key-dates-list
      ref="keyDatesList"
      @edit="editKeyDate"
    />

    <b-sidebar
      id="edit-key-date-right"
      title="Edit Key Dates"
      bg-variant="white"
      right
      shadow
      backdrop
      width="600px"
      @shown="handleShowAOMTextEditor"
      @hidden="handleHideAOMTextEditor"
    >
      <div>
        <edit-key-date
          :show-a-o-m-text-editor="showAOMTextEditor"
          :key-date-id="keyDateId"
          @edited="refreshList"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddKeyDate from "./AddKeyDate.vue";
import EditKeyDate from "./EditKeyDate.vue";
import KeyDatesList from "./KeyDatesList.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";


export default {
  components: {
    ActionButton,
    BCard,
    BButton,
    BRow,
    BCol,
    AddKeyDate,
    EditKeyDate,
    KeyDatesList,
    BSidebar,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      keyDateId: 0,
      showAOMTextEditor: false
    };
  },

  methods: {
    editKeyDate(id) {
      this.keyDateId = id;
      this.$root.$emit("bv::toggle::collapse", "edit-key-date-right");
    },

    refreshList() {
      this.$refs.keyDatesList.loadItems();
      this.keyDateId = 0;
    },

    handleShowAOMTextEditor () {
      this.showAOMTextEditor = true;
    },

    handleHideAOMTextEditor () {
      this.showAOMTextEditor = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 400 !important;
}
</style>
<style lang="scss">
  $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #add-key-date-right___title__ {
        font-size: .8em;
        flex: 2;
      }
    }
</style>
